.responsive-image {
  box-sizing: border-box;
  position: relative;

  .image {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 8px;
    overflow: hidden;
    border-radius: 5px;

    &::before {
      display: block;
      padding-top: 50%;
      padding-bottom: 50%;
      content: "";
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      max-width: 100%;
      max-height: 100%;
    }
  }
}
