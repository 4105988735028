.form-control {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #d7d4d4;
  background-color: #ffffff;
  font-size: 14px;
  color: #000 !important;
  font-weight: 400;
}

.form-need-validate .form-control.ng-invalid {
  border-color: red;
}

.form-control.ng-invalid.ng-touched,
.form-control.error-form-control {
  border: solid 1px #f26c4f;
  color: #f26c4f;
}
