.mat-menu-0 {
  min-width: 147px !important;
  min-height: auto !important;
  .items {
    .item {
      display: flex;
      align-items: baseline;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
    }
  }
}
