nz-time-picker {
  width: 100%;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #01A1DA !important;
  padding: 0.43rem 0px !important;
  box-shadow: unset !important;
  line-height: 0 !important;
  &-blue {
    border-color: #01A1DA !important;
  }
  input::-webkit-input-placeholder {
    display: none !important;
  }
}
.ant-picker-suffix, .ant-picker-clear {
  display: none;
}

.ant-picker-time-panel-column:after {
  display: none;
}


