%scroll-container {
  overflow-y: overlay;
  $mainColor: #e7e7e7;
  $maxWidth: 10px;
  $minWidth: 5px;

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: $mainColor transparent;

  @supports (-moz-appearance: none) {
    overflow-y: auto;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: $maxWidth;
  }

  &::-webkit-scrollbar-thumb {
    background: $mainColor;
    width: $minWidth;
    background-clip: padding-box;
    border-left: $maxWidth - $minWidth solid transparent;
  }
}

%hide-scroll-container {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
