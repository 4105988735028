@import 'apps/studio/src/app/styles/colors';

.btn-basic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0px 20px;
  border: 0;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  &:disabled {
    border: 1px solid $buttonBorderDisabled;
    color: $buttonColorDisabled;
    background: $buttonBackgroundDisabled;
    cursor: unset;
  }
}
.btn-basic_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  height: 30px;
  border-radius: 5px;
  padding: 0 0.5rem;
  font-size: 12px;
  position: relative;
  &:disabled {
    border: 1px solid $buttonBorderDisabled;
    color: $buttonColorDisabled;
    background: $buttonBackgroundDisabled;
    cursor: unset;
  }
}
.btn-0 {
  @extend .btn-basic;
  min-width: 40px;
  padding: 0;
  color: $buttonColor_12;
  background-color: #FFFFFF;
  border-radius: 7px;
}
.btn-3 {
  @extend .btn-basic;
  background: $buttonBackground_0;
  color: $buttonColor_1;
  border-radius: 36px;
}
.btn-4 {
  @extend .btn-basic;
  background: $buttonBackground_1;
  color: $buttonColor_0;
  border-radius: 36px;
}
.btn-5 {
  @extend .btn-basic;
  background: $buttonBackground_0;
  color: $buttonColor_1;
  border-radius: 7px;
  cursor: pointer;

  &.s {
    height: auto;
    padding: 0;
    padding: 0.6rem;
  }
}
.btn-6 {
  @extend .btn-basic;
  background: $buttonBackground_0;
  color: $buttonColor_1;
  border-radius: 7px;
  cursor: pointer;
}
.btn-7 {
  @extend .btn-basic;
  background-color: $buttonBackground_3;
  color: $buttonColor_1;
  border-radius: 7px;
  cursor: pointer;

  &.s {
    height: auto;
    padding: 0;
    padding: 0.6rem;
  }
}
.btn-8 {
  @extend .btn-basic;
  color: $buttonColor_0;
  background-color: $buttonBackground_4;
  border: 1px solid $buttonBorder_0;
  border-radius: 7px;

  i {
    margin-left: 10px;
  }
}
.btn-9 {
  @extend .btn-basic;
  color: $buttonColor_0;
  background-color: $buttonBackground_4;
  border-radius: 7px;
}
.btn-10 {
  @extend .btn-basic;
  color: $buttonColor_0;
  background-color: unset;
  border: 1px solid $buttonBorder_0;
  border-radius: 7px;

  .fas {
    margin-left: 0.3rem;
  }
}
.btn-11 {
  @extend .btn-basic;
  color: $buttonColor_1;
  background-color: $buttonBackground_11;
  border-radius: 7px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.btn-12 {
  @extend .btn-basic;
  color: $buttonColor_12;
  background-color: $buttonBackground_4;
  border-radius: 7px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.btn-13 {
  @extend .btn-basic;
  color: white;
  background-color: $buttonBackground_13;
  border-radius: 7px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.btn-14 {
  @extend .btn-basic;
  color: white;
  background-color: #df5c66;
  border-radius: 7px;
}
.btn-15 {
  @extend .btn-basic;
  padding: 0 14px;
  color: #E15F5F;
  background-color: #FFFFFF;
  border-radius: 7px;
}
.btn-16 {
  @extend .btn-basic;
  color: #A8CF16;
  background-color: #F5F9E9;
  border-radius: 7px;
}
.btn-17 {
  @extend .btn-basic;
  padding: 0px 13px;
  color: #01A1DA;
  background-color: #DEF6FF;
  border-radius: 7px;
}
.btn-18 {
  @extend .btn-basic;
  padding: 0px 13px;
  color: #E15F5F;
  background-color: #FFEDED;
  border-radius: 7px;
}
.btn-181 {
  @extend .btn-basic;
  padding: 0px 13px;
  color: #A8CF16;
  background-color: #F5F9E9;
  border-radius: 7px;
}
.btn-19 {
  @extend .btn-basic;
  padding: 0px 13px;
  color: $buttonColor_0;
  background-color: white;
  border-radius: 7px;
}
.btn-20 {
  @extend .btn-basic;
  padding: 0;
  color: $buttonColor_12;
  gap: 0.5rem;
}
.btn-21 {
  @extend .btn-basic;
  background: #F0F0F0;
  color: #424242;
  border-radius: 7px;
  cursor: pointer;
}

.btn-22 {
  @extend .btn-basic;
  background-color: #CDD620;
  color: #ffffff;
  border-radius: 7px;
  cursor: pointer;
}
.btn-23 {
  @extend .btn-basic;
  padding: 0px 13px;
  color: $buttonColor_0;
  background-color: #DEF6FF;
  border-radius: 7px;
}
.btn-24 {
  @extend .btn-basic;
  padding: 0px 13px;
  color: $buttonColor_0;
  background-color: #F5F6F5;
  border-radius: 7px;
}

.btn-25 {
  @extend .btn-basic;
  color: $buttonColor_0;
  min-width: 30px;
  height: 30px;
  background-color: #CEF1FF;
  border-radius: 60%;
  padding: 0;
  &:hover {
    text-decoration: unset;
  }
}
.btn-26 {
  @extend .btn-basic;
  min-width: 40px;
  padding: 0;
  color: $buttonColor_0;
  background-color: #DEF6FF;
  border-radius: 7px;
}
.btn-27 {
  @extend .btn-basic;
  min-width: 40px;
  padding: 0;
  color: $buttonColor_0;
  background-color: #FFFFFF;
  border-radius: 7px;
}

.btn-28 {
  @extend .btn-basic_1;
  background-color: #E5EEF4;
}
.btn-29 {
  @extend .btn-basic_1;
  background-color: #CDD620;
}
.btn-30 {
  @extend .btn-basic_1;
  background-color: #FA8E8F;
}
.btn-31 {
  @extend .btn-basic;
  color: #ffffff;
  background-color: #2d8cff;
  height: 30px;
  border-radius: 5px;
  padding: 0 0.5rem;
}
.btn-32 {
  @extend .btn-basic;
  padding: 0 0.5rem;
  color: #ffffff;
  border-radius: 5px;
  height: 30px;
  background-color: #b7bac1;
}
.btn-33 {
  @extend .btn-basic;
  min-width: 30px;
  height: 30px;
  padding: 0;
  color: #FFFFFF;
  background-color: $buttonColor_0;
  border-radius: 7px;
}
.btn-delete {
  border: 0;
  background: unset;
  cursor: pointer;
  color: #E15F5F;

  &:disabled {
    color: $buttonColorDisabled;
    cursor: unset;
  }
}
.btn-mat {
  padding-bottom: 1.34375em;
}
.btn-34 {
  background: #FFFFFF;
  opacity: 0.7;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  color: #E15F5F;
  border: 0;
}

.circle {
  background: rgba(255, 255, 255, 0.58);
  color: red;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  justify-content: center;

  &.gray {
    background: #F2F5F6;
  }
}
