.context-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: auto;
  right: 0;
  margin-top: .5rem;
  min-width: 150px;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.2);
  animation: appear 200ms ease-in-out;
  white-space: nowrap;
  border-radius: 6px;
  z-index: 3;

  .menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    transition: 200ms background-color ease-in-out, 200ms color ease-in-out;
    font-size: 11px;

    &.item-delete {
      color: #e15f5f;
    }

    &.phone {
      color: #01A1DA;
    }

    &.email {
      font-weight: 500;
      font-size: 11px;
      color: #CDD620;
    }

    i {
      margin-right: 10px;
      color: #01a1da;
      transition: 200ms color ease-in-out;

      &.icon-delete {
        color: #e15f5f;
      }
    }
  }
}

.uv-mat-menu-context {
  .item {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 16px;
    cursor: pointer;
    &.phone {
      color: #01A1DA;
    }
    &.email {
      color: #CDD620;
    }
    &.delete {
      color: #e15f5f;
    }
  }
}
