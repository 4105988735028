@import 'apps/studio/src/app/styles/colors';
@import 'apps/studio/src/app/styles/scroll';
.wrap-uv-modal {
  display: contents;
  .uv-modal {
    border-radius: 10px;
    overflow: hidden;
    width: 410px;
    max-height: 80%;
    background-color: $modalBackground;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 99999999;
    &.resizable {
      width: auto;
      @media (max-width: $sreenSmall) {
        width: 100%;
      }
    }
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 23px 0px;

      .uv-modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: $modalTitleColor;
      }

      .uv-modal-close {
        position: absolute;
        right: 23px;
        top: 18px;
        cursor: pointer;
      }
    }

    .uv-modal-body {
      overflow: overlay;
      @extend %scroll-container;
    }
  }
}

.uv-modal-confirm {
  z-index: 1099950;

  .modal-dialog {
    width: 410px;

    .wrap-btn-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
}

.modal-content {
  border-radius: 10px;
}

.wrap-uv-confirm-modal {
  z-index: 99999;
}
.wrap-uv-modal-drag {
  display: contents;
  .uv-modal {
    border-radius: 10px;
    overflow: hidden;
    width: 340px;
    max-height: 80%;
    background-color: $modalBackground;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 99999999;
    box-shadow: 0px 19px 50px rgba(0, 0, 0, 0.25);

    header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      background-color: #F2F7FA;
      padding: 0.5rem 1.5rem 0.5rem;

      .uv-modal-close {
        position: absolute;
        right: 23px;
        top: 18px;
        cursor: pointer;
      }
    }

    .uv-modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: $modalTitleColor;
    }

    .uv-modal-body {
      @extend %scroll-container;
    }
  }
}
