@use '@angular/material' as mat;
@include mat.core();

$light-primary-text: unset;
$red-palette_: (
  50: #01a1da,
  100: #01a1da,
  200: #01a1da,
  300: #01a1da,
  400: #01a1da,
  500: #01a1da,
  600: #01a1da,
  700: #01a1da,
  800: #01a1da,
  900: #01a1da,
  A100: #01a1da,
  A200: #01a1da,
  A400: #01a1da,
  A700: #01a1da,
  contrast: (
    50: unset,
    100: unset,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$pink-palette: (
  50: #A8CF16,
  100: #A8CF16,
  200: #A8CF16,
  300: #A8CF16,
  400: #A8CF16,
  500: #A8CF16,
  600: #A8CF16,
  700: #A8CF16,
  800: #A8CF16,
  900: #A8CF16,
  A100: #A8CF16,
  A200: #A8CF16,
  A400: #A8CF16,
  A700: #A8CF16,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$my-primary: mat.define-palette($red-palette_);
$my-accent: mat.define-palette($pink-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent
  )
));
@include mat.all-component-themes($my-theme);
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import 'colors';
@import 'screen-sizes';
@import 'scroll';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~ngx-toastr/toastr.css';
@import 'uv/uv-index';

html {
  font-size: 14px;
  line-height: 1.3;
  height: 100%;
  @extend %hide-scroll-container;
}

* {
  box-sizing: border-box;
}

body {
  width: 100%;
  min-width: 300px;
  max-width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-color: #EDEFF0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: $defaultFontColor;

  @media (min-width: $sreenLarge) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header header header'
      'lsidebar main rsidebar'
      'footer footer footer';
    grid-gap: 20px;
  }

  & > header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  & > footer {
    grid-area: footer;
    margin-bottom: auto;
  }

  & > aside[data-component-name='sidebar-left'] {
    grid-area: lsidebar;
    margin-bottom: auto;
    margin-left: 20px;
  }

  & > aside[data-component-name='sidebar-right'] {
    grid-area: rsidebar;
    margin-bottom: auto;
    margin-right: 20px;

    @media (max-width: $sreenLarge) {
      margin-right: 0;
    }
  }
}

button,
input,
textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: inherit;
  font-style: inherit;
}

a {
  color: $blueActiveElement;
  text-decoration: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #525252;
  font-weight: 500;
  font-size: 2rem;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 0 0.6em 0;
}

h2 {
  font-size: 1.5rem;
}

ul, li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

li {
  list-style: none;
}
