$controlBorderColor: #d7d4d4;
$stormyBlue: #517ba6;
$colorTextBlack: #30363c;
$alertColor: #d9001a;
$invalidControlBackgroundColor: rgba(255, 0, 0, 0.06);
$placeholderColor: rgba(48, 54, 60, 0.4);
$mainBackgroundColor: #F2F5F6;
$link-color: #03a9f4;
$buttonBackground_0: #01A1DA;
$buttonBackground_1: #F2F5F7;
$buttonBackground_2: #A1A1A1;
$buttonBackground_3: #a8cf16;
$buttonBackground_4: #FFFFFF;
$buttonBackground_11: #CDD620;
$buttonBackground_13: #30363c;
$buttonColor_0: #01A1DA;
$buttonColor_1: #FFFFFF;
$buttonColor_12: #E15F5F;
$buttonDeleteColor: #E15F5F;
$buttonColorDisabled: #BAB9BA;
$buttonBackgroundDisabled: #FFFFFF;
$buttonBorderDisabled: #ECECEC;
$buttonBorder_0: #01A1DA;
$modalBackground: #FFFFFF;
$modalTitleColor: #424242;
$checkBox_1_Background: #A8CF16;
$checkBox_2_Background: #01A1DA;
$checkBox_3_Background: #E15F5F;
$checkBox_1_BackgroundDisabled: #ccc;
$checkBox_2_BackgroundDisabled: #ccc;

$uv-select-1_selectedItemColor: #01A1DA;
$uv-select-1_arrowColor: #01A1DA;
$uv-select-1_itemColor: #01A1DA;
$uv-select-1_darkBackground: #3A3A3A;
$uv-select-1_darkBackgroundActive: #313131;
