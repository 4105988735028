.uv-panel {
  .mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }


  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: unset;
  }

  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: unset;
  }
}
