label {
  color: #898989;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;

  &.label-accent {
    color: #517ba6;
    font-size: 15px;
    font-weight: 500;
  }

  &.fw-600 {
    font-weight: 600;
  }
}

.uv-label-0 {
  color: #b8b8b8;
  font-size: 12px;
  font-weight: 700;
}

.uv-label-1 {
  font-weight: 700;
  font-size: 16px;
  color: #404040;
}

.uv-label-2 {
  font-size: 12px;
  font-weight: 500;
  color: #7A7A7A;
}

.uv-label-3 {
  font-size: 14px;
  font-weight: 700;
  color: #515151;
}

.uv-label-4 {
  font-weight: 500;
  font-size: 16px;
  color: #404040;
}

.uv-label-5 {
  font-weight: 400;
  font-size: 16px;
  color: #404040;
}

.uv-label-6 {
  font-weight: 400;
  font-size: 20px;
}

.uv-label-7 {
  font-weight: 700;
  font-size: 16px;
  color: #525252;
}

.uv-label-8 {
  font-weight: 400;
  font-size: 16px;
  color: #525252;
}

.uv-label-9 {
  font-weight: 400;
  font-size: 12px;
  color: #BAB9BA;
}
.uv-label-10 {
  font-weight: 700;
  font-size: 24px;
  color: #404040;
}
.uv-label-11 {
  font-weight: 700;
  font-size: 18px;
}

.label-upload-file {
  input {
    display: none;
  }

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.label-upload-file-border {
  border-radius: 12px;
  box-shadow: 0 5px 27px 3px rgba(0, 0, 0, 0.2);
  border: 2px solid #ccc;
}

.label-time {
  position: absolute;
  z-index: 2;
  color: #BBB;
  font-weight: 400;
  transform: translateY(-0.2em) scale(0.89);
  left: 0.9em;
}
