$defaultFontColor: #525252;

$stormyBlue: #517ba6;
$aqua: #28b7b6;
$alertColor: #d9001a;
$invalidControlBackgroundColor: rgba(255, 0, 0, 0.06);
$placeholderColor: rgba(48, 54, 60, 0.4);
$controlBorderColor: #dddddd;
$blueActiveElement: #01a1da;
$chartreuse: #a8cf16;
