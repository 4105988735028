.uv-table-0 {
  background: unset;

  mat-row, mat-header-row, mat-footer-row {
    border-bottom-width: 0;
    border-bottom: 1px solid #E3EEF2;
  }

  mat-row, mat-footer-row {
    min-height: 40px;
  }

  .mat-header-cell {
    border-bottom: 1px solid #E3EEF2;
  }

  mat-header-cell {
    color: #BAB9BA;
  }
}
