@import "../screen-sizes";
@import "form";
@import "buttons";
@import "modal";
@import "label";
@import "title";
@import "select";
@import "table";
@import "context-menu";
@import "link";
@import "slide";
@import "image";
@import "styles/colors";
@import "panel";
@import "radio";
@import "datepicker";
@import "nz-time-picker";
@import "menu";

button {
  border: 0;
  background-color: unset;
}

.z-index-7 {
  z-index: 7;
}

.cursor-unset {
  cursor: unset !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move;
}

small {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: #838a91;
}

.hide-el {
  display: none !important;
}

.width-150px {
  width: 150px !important;
}

.border-bottom {
  border-bottom: 1px solid #DCDCDC;
}

.border-top {
  border-top: 1px solid #DCDCDC;
}

.white-space-nowrap {
  white-space: nowrap;
}

.wrap-btn-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

ul {
  padding: 0;
  li {
    list-style: none;
  }
}

.blue {
  color: #03a9f4;
}

.red {
  color: $alertColor;
}

.error-message {
  margin-top: -10px;
  font-size: 12px;
  color: $alertColor;
}

.ant-btn-primary {
  background-color: #01A1DA !important;
}

.search-icon {
  color: #7A7A7A;
}

.wr-3 {
  width: 3rem !important;
}

.wr-4 {
  width: 4rem !important;
}

.wr-5 {
  width: 5rem !important;
}

.wr-10 {
  width: 10rem !important;
}
.wr-15 {
  width: 15rem !important;
}

.uv-row {
  display: flex;
  align-items: baseline;
  grid-gap: 1rem;
}

.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper, .mdc-text-field {
  padding: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled), .mat-mdc-form-field-focus-overlay, .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: unset !important;
}
.mat-mdc-checkbox.mat-primary, .mat-mdc-checkbox.mat-accent  {
  --mdc-checkbox-selected-checkmark-color: #ffffff !important;
  --mdc-checkbox-unselected-focus-icon-color: #ffffff !important;
  --mdc-checkbox-unselected-hover-icon-color: #ffffff !important;
}
label {
  margin-bottom: 0 !important;
}
.label-checkbox-in-footer {
  a {
    pointer-events: all;
  }
}
