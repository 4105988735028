.mat-form-field-appearance-fill .mat-form-field-flex {
  background: unset;
  padding: 0 !important;
  border: 0 !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #01a1da;
}

.mat-form-field-label {
  color: #BBB !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, .87);
}

.mat-option.mat-active,
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: #E5EEF3 !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #CDD620;
}

.uv-select-1 {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 7px;
  padding: 0.643rem;

  &--dark {
    background: transparent;
    border: transparent;
    border-bottom: 1px solid $buttonColor_1;
    border-radius: unset;

    ::ng-deep {
      .mat-select-trigger {
        .mat-select-value {
          color: $buttonColor_1;

          .mat-select-placeholder {
            color: $buttonColor_1;
          }
        }

        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: $buttonColor_1;
          }
        }
      }
    }
  }

  &--light {
    background: transparent;
    border: transparent;
    border-bottom: 1px solid $buttonBackground_13;
    border-radius: unset;

    ::ng-deep {
      .mat-select-trigger {
        .mat-select-value {
          color: $buttonBackground_13;

          .mat-select-placeholder {
            color: $buttonBackground_13;
          }
        }

        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: $buttonBackground_13;
          }
        }
      }
    }
  }

  .mat-select-arrow-wrapper {
    display: initial;

    .mat-select-arrow {
      border-top: unset;

      &::before {
        font-size: 12px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f078";
        color: $uv-select-1_arrowColor;
      }
    }
  }

  .mat-select-value {
    color: $uv-select-1_selectedItemColor;
  }

  .mat-option-text {
    color: $uv-select-1_itemColor;
  }
}

.uv-select-1-option {
  &--dark {
    color: $buttonColor_1;
    background: $uv-select-1_darkBackground !important;

    &.mat-active,
    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled) {
      background: $uv-select-1_darkBackgroundActive !important;
    }

    & ::ng-deep {
      .mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
        color: $buttonColor_1;
      }

      .mat-pseudo-checkbox-checked,
      .mat-pseudo-checkbox-indeterminate {
        background: #CDD620;
      }
    }
  }

  .mat-option-text {
    display: flex;
    align-items: center;
  }

  .icon-studio {
    width: 21px;
    height: 21px;
    border: 1px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    color: #01A1DA;
    font-style: normal;
    font-weight: 700;
    font-size: 11.0526px;
    margin-right: 0.5rem;
  }

  .icon-sport-hub {
    width: 21px;
    height: 21px;
    border: 1px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    color: #CDD620;
    font-style: normal;
    font-weight: 700;
    font-size: 11.0526px;
    margin-right: 0.5rem;
  }
}
.ng-selected-studio {
  width: 220px;
  .ng-select-container {
    background: unset !important;
    justify-content: space-between;
  }
  .ng-select-container:hover {
    box-shadow: unset !important;
  }
  .ng-value-container {
    flex: unset !important;
    padding-left: .7rem !important;
  }
  .ng-value-container .ng-input {
    left: 0 !important;
  }
  .ng-select-container .ng-value-container .ng-input {
    padding-left: 0px !important;
  }
  .ng-dropdown-panel.ng-select-bottom {
    top: unset !important;
  }
  .ng-arrow-wrapper .ng-arrow:before {
    content: "\f0d7";
    color: #7A7A7A;
  }
  .ng-value-label{
    font-weight: 400;
    font-size: 16px;
    color: #7A7A7A;
  }
}

