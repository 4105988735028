.uv-link {
  font-weight: 400;
  color: #01A1DA;
  cursor: pointer;

  &.red {
    color: #E16C6C;
  }

  &:hover {
    text-decoration: underline;
  }

  &.inactive {
    color: #838a91;
    cursor: none;

    &:hover {
      text-decoration: initial;
    }
  }
}

.uv-link-0 {
  font-size: 16px;
  font-weight: 700;
  color: #01A1DA;
  cursor: pointer;
}
